@import './assets/styles/mixins';

.header {
  position: relative;
  flex-shrink: 0;
  color: var(--clr-text-main);
  background-color: $black;
  transition: background-color 0.2s;
  padding: 16px 0;
  font-size: 0;
  line-height: 0;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  // @include maxWidth($tablet-l) {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: 100;
  // }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 33px;

  @include maxWidth($tablet-l) {
    display: none;
  }
}

.nav {
  display: flex;
  gap: 25px;
  font-weight: 500;
  font-size: 21px;
  align-items: center;
  transition: 0.16s;
  line-height: 50px;

  a {
    cursor: pointer;
  }

  @include maxWidth($desktop-s) {
    font-size: 18px;
  }

  @include maxWidth($tablet-l) {
    display: none;
  }
}

.area {
  font-weight: 500;
  font-size: 21px;
  line-height: 50px;

  @include maxWidth($desktop-s) {
    font-size: 16px;
  }

  &__wrapper {
    position: relative;

    > ul {
      font-weight: 500;
      font-size: 21px;
      line-height: 50px;
    }

    &:hover {
      color: $gray-text;

      > ul {
        transform: scaleY(1);
      }
    }
  }

  &__active {
    color: $gray-text;
  }
}

.button_search {
  cursor: pointer;
}

.burger {
  position: relative;
  height: 23px;
  width: 25px;
  display: none;
  flex-direction: column;
  justify-content: space-between;

  span {
    display: block;
    width: 100%;
    height: 3px;
    background: $white;
    border-radius: 20px;
  }

  @include maxWidth($tablet-l) {
    display: flex;
  }
}
