@import './assets/styles/mixins';

.panel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 150;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  background: $black;
  transform: translateX(-100vw);
  opacity: 0;
  transition: transform 0.4s ease, opacity 0.4s ease;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);

  @include maxWidth($tablet-l) {
    padding-top: 28px;
  }

  &_open {
    transform: translateX(0);
    opacity: 1;
  }

  &_actions {
    display: flex;
    align-self: flex-end;
    align-items: center;
    gap: 42px;
  }
}

.close {
  margin-right: 44px;
  cursor: pointer;
  align-self: flex-end;
  justify-self: flex-end;
}

.content {
  margin: 84px auto;
  display: flex;
  flex-direction: column;

  @include maxWidth($tablet-l) {
    margin-top: 50px;
  }

  &_actions {
    width: 100%;
    max-width: 1084px;
    margin: 0 auto 79.66px;

    @include maxWidth($tablet-s) {
      margin: 0 auto 50px;
    }

    @include maxWidth($mobile-l) {
      margin: 0 auto 36px;
    }

    &__button {
      padding: 17.8402px 19.8224px 18.8313px;
      background: $secondary-black;
      border-radius: 8.38533px;
      font-weight: 700;
      font-size: 35.1464px;
      line-height: 29px;
      text-align: center;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      color: $white;
      transition: 0.1s;

      &:hover {
        opacity: 0.8;
      }

      @include maxWidth($desktop-s) {
        padding: 13.8402px 19.8224px 14.8313px;
        font-size: 24px;
      }

      @include maxWidth($tablet-l) {
        display: none;
      }
    }

    form {
      display: flex;
      gap: 53px;
    }
  }

  &_result {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 45px;

    @include maxWidth($desktop-s) {
      gap: 35px;
    }

    @include maxWidth($tablet-l) {
      gap: 30px;
    }

    @include maxWidth($mobile-xl) {
      gap: 36px;
    }

    @include maxWidth($mobile-l) {
      gap: 20px;
    }

    // @include maxWidth($desktop-s) {
    //     gap: 40px;
    // }

    // @include maxWidth($mobile-xl) {
    //     gap: 30px;
    // }

    // @include maxWidth($mobile-l) {
    //     gap: 20px;
    // }
  }

  &_input {
    color: $white;
    width: 100%;
    background: transparent;
    font-weight: 500;
    font-size: 40px;
    line-height: 130%;
    padding-left: 14px;
    border-bottom: 1.4px solid $gray-text;

    @include maxWidth($tablet-l) {
      font-weight: 700;
      font-size: 30px;
      line-height: 100%;
    }

    @include maxWidth($tablet-s) {
      padding-left: 0;
    }

    ::placeholder {
      color: $gray-text;
    }
  }

  &_nothing_found {
    padding-top: 110px;
    margin: 0 auto;
    // padding-top: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;

    @include maxWidth($mobile-xl) {
      gap: 18px;
    }

    &__title {
      color: $gray-medium;

      & span {
        color: $white;
      }
    }

    &__descr {
      font-family: 'Monument Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      color: $gray-medium;

      @include maxWidth($tablet-l) {
        font-size: 18px;
      }

      @include maxWidth($mobile-xl) {
        font-size: 14px;
      }
    }
  }
}
