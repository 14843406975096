@import './assets/styles/mixins';

.categories_list {
  max-width: 100%;
  margin-top: 55px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  gap: 15px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    height: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @include maxWidth($desktop-s) {
    margin-top: 38px;
  }

  @include maxWidth($mobile-xl) {
    gap: 8px;
  }
}

.button_wrapper {
  > button {
    position: relative;
    border-radius: 10px;
    background-color: transparent;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    padding: 7px 16px 12px;
    white-space: nowrap;
    text-transform: none;

    &::after {
      display: block;
      content: '';
      background-color: $blue;
      height: 2px;
      width: 0%;
      bottom: 0;
      left: 50%;
      position: absolute;
      transition: all 0.3s ease-in-out;
      transform: translateX(-50%);
    }

    &:hover,
    &:focus-visible {
      background-color: initial;
      &::after {
        width: 90%;
      }
    }

    &:focus {
      background: $blue;
    }

    @include maxWidth($tablet-l) {
      background-color: $black-light;

      &:hover {
        &::after {
          display: none;
        }
      }
    }

    @include maxWidth($mobile-xl) {
      border-radius: 6.56px;
      font-size: 13px;
      padding: 5.59px 13.49px 7.87px;
    }

    &__active {
      background: $blue;

      &:hover::after {
        display: none !important;
      }
    }
  }

  &--active {
    & > button {
      background: $blue;
      pointer-events: none;

      &:hover::after {
        display: none !important;
      }
    }
  }
}
